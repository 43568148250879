<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="fetchItems"
  >
    <template #button-content>
      <feather-icon
        :badge="$store.state['ecommerce'].cartItemsCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Carrello
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ $store.state['ecommerce'].cartItemsCount }} Prodotti
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="items.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in items"
        :key="item.id"
      >
        <template #aside>
          <b-img
            :src="item.link"
            :alt="item.name"
            rounded
            width="62px"
          />
        </template>
        <feather-icon
          v-if="item.code !== '000000' && (item.type !== 'macchina' && first_cart)"
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeItemFromCart(item)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.name }}
            </b-link>
          </h6>
          <small class="cart-item-by">V.P.C. {{ item.vpc }}</small>
        </div>
        <div class="cart-item-qty ml-1">
          <b-form-spinbutton
            v-if="item.code !== '000000' && item.type !== 'macchina'"
            v-model="item.quantity"
            min="0"
            size="sm"
            :readonly="blockInputQuantity"
            @change="blockInputQuantity = true; handleItemQuantity(item)"
          />
        </div>

        <h5 class="cart-item-price">
          €{{ item.price }}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="items.length"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Totale:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          €{{ totalAmount }}
        </h6>
      </div>
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Totale V.P.C.:
        </h6>
        <h6 class="text-secondary font-weight-bolder mb-0">
          {{ totalVpc }}
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'shop.checkout' }"
      >
        Checkout
      </b-button>
    </li>

    <p
      v-if="!items.length"
      class="m-0 p-1 text-center"
    >
      Il tuo carrello è vuoto
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      first_cart: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      blockInputQuantity: false,
    }
  },
  computed: {
    totalAmount() {
      let total = 0
      this.items.forEach(i => { total += i.price * i.quantity })
      return total
    },
    totalVpc() {
      let total = 0
      this.items.forEach(i => { total += i.vpc })
      return total
    },
  },
  // mounted() {
  //   setInterval(() => {
  //     this.updateItems()
  //   }, 5000)
  // },
  methods: {
    fetchItems() {
      this.$store.dispatch('ecommerce/fetchCartProducts')
        .then(response => {
          this.items = response.data.data.items
          this.first_cart = response.data.data.meta.first_cart
          // Update count in cart user state
          this.$store.commit('ecommerce/UPDATE_CART_ITEMS_COUNT', response.data.data.meta.total)
        })
    },
    removeItemFromCart(item) {
      const data = {
        item_id: item.id,
        quantity: 0,
      }
      this.$store.dispatch('ecommerce/removeProductFromCart', data)
        .then(() => {
          const itemIndex = this.items.findIndex(p => p.id === item.id)
          this.items.splice(itemIndex, 1)

          // Update count in cart user state
          this.$store.commit('ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
        })
    },
    handleItemQuantity(item) {
      const data = {
        item_id: item.id,
        quantity: item.quantity,
      }
      this.$store.dispatch('ecommerce/addProductInCart', data)
        .then(() => {
          this.fetchItems()
          this.blockInputQuantity = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
